<template>
    <div class="container" id="EnrollAuditDetail">
        <el-page-header @back="$router.back()" :content="'报名详情'"></el-page-header>
        <div class="EnrollAuditDetail_content">
            <template v-if="false">
            <el-descriptions title="报名统计"></el-descriptions>
            <div class="statistics">
                <p>累计已报名共有 44 条记录，合计 15 支队伍 159 个人。其中 31 条记录审核通过，合计 16 支队伍 98  名运动员。</p>
                <el-button type="primary" size="small">刷新统计</el-button>
            </div>
            <el-row>
                <el-table
                    v-loading="statistics.loading"
                    element-loading-text="数据刷新中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :data="statistics.list"
                    border
                    size="mini"
                    style="width: 100%"
                    show-summary
                    sum-text="合计"
                    :summary-method="getSummaries"
                >
                    <el-table-column
                        align="center"
                        prop="GroupName"
                        label="组别"
                        width="150">
                    </el-table-column>
                    <el-table-column align="center" width="150">
                        <div slot="header">
                            <p>累计已报名</p>
                            <p>（其中今日新增）</p>
                        </div>
                        <template>
                            <el-button type="text">
                                8支队伍（0）
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="已修改未提交" align="center">
                        <el-table-column label="上次审核通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="上次审核不通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="已提交待审核" align="center">
                        <el-table-column label="上次审核通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="上次审核不通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="新提交" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="审核通过" align="center">
                        <el-table-column label="未被举报" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="有运动员被举报" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="审核不通过" align="center" min-width="150">
                        <template>
                            <el-button type="text">
                                8支队伍（0）
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            </template>
            <el-descriptions title="筛选条件"></el-descriptions>
            <el-form :model="form" label-width="100px" size="small" class="form">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="代表机构：">
                            <el-input v-model.trim="form.companyName" placeholder="机构全称或简称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="代表队：">
                            <el-input v-model.trim="form.teamName" placeholder="代表队名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="运动员：">
                            <el-input v-model.trim="form.memberName" placeholder="运动员姓名"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="5">
                        <el-form-item label="组别：">
                            <el-select v-model="form.groupId" placeholder="请选择">
                                <el-option label="不限" :value="0"></el-option>
                                <el-option v-for="(item,index) in groups" :key="index"
                                    :label="item.GroupName" 
                                    :value="item.GroupId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="审核状态：">
                            <el-select v-model="form.auditStatus" placeholder="请选择">
                                <el-option v-for="(item,index) in auditStatusList" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="上次审核状态：" label-width="140px">
                            <el-select v-model="form.prevAuditStatus" placeholder="请选择">
                                <el-option v-for="(item,index) in prevAuditStatusList" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="运动员被举报：" label-width="170px">
                            <el-select v-model="form.isInform" placeholder="请选择">
                                <el-option v-for="(item,index) in informs" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="排序：">
                            <el-select v-model="form.sort" placeholder="请选择">
                                <el-option v-for="(item,index) in sorts" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-button 
                            type="primary" size="small" 
                            style="margin-left:50px;width:100px"
                            @click="query()"
                        >查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div class="statistics" v-if="isGet">
                <p v-if="AllCount == 0">没有满足条件的记录</p>
                <p v-else>共有 {{AllCount}} 条记录满足条件，{{(list.length == AllCount) ? '已全部加载' : `已加载前 ${list.length} 条记录`}}。<!--<el-button type="text" style="padding:8px 0">将所有记录设置为审核不通过</el-button>--></p>
            </div>
            <el-row>
                <el-table
                    v-loading="loading"
                    element-loading-text="数据刷新中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :data="list"
                    border
                    size="mini"
                    style="width: 100%"
                >
                    <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                    <el-table-column label="比赛项目" width="100" prop="EventsName"></el-table-column>
                    <el-table-column label="组别" prop="LeagueGroupName"></el-table-column>
                    <el-table-column label="代表队" prop="DeputationName"></el-table-column>
                    <el-table-column label="参赛者" prop="TeamName"></el-table-column>
                    <el-table-column label="代表机构" prop="CompanyName" min-width="100"></el-table-column>
                    <el-table-column label="报名时间" min-width="100">
                        <template slot-scope="scope">
                            <span>{{dateFormat(scope.row.EnrollTime, 'yyyy-MM-dd HH:mm:ss')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="提交时间" min-width="100">
                        <template slot-scope="scope">
                            <span>{{dateFormat(scope.row.SubmitTime, 'yyyy-MM-dd HH:mm:ss')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核时间" min-width="100">
                        <template slot-scope="scope">
                            <span>{{dateFormat(scope.row.AuditTime, 'yyyy-MM-dd HH:mm:ss')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态" width="100">
                        <template slot-scope="scope">
                            <span>{{['未提交','待审核','审核通过','审核不通过'][scope.row.AuditStatus]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="上次审核" width="80">
                        <template slot-scope="scope" v-if="!([2,3].includes(scope.row.AuditStatus))">
                            <span v-if="scope.row.LastAuditStatus == 2">通过</span>
                            <span v-if="scope.row.LastAuditStatus == 3">不通过</span>
                            <span v-if="scope.row.LastAuditStatus == null">无</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="运动员被举报" width="80">
                        <template slot-scope="scope">
                            <template v-if="[1,3].includes(scope.row.GroupType)">
                                <span>{{['','否','是','部分'][scope.row.ImpeachState - 1]}}</span>
                            </template>
                            <template v-else>
                                <span>{{['','无','全部','部分'][scope.row.ImpeachState - 1]}}</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <el-button 
                            size="mini"
                            type="primary"
                            slot-scope="scope"
                            @click="openDetail(scope.$index, scope.row.TeamId, scope.row.GroupType)"
                        >详情</el-button>
                    </el-table-column>
                    <div slot="append" class="load_more" v-show="list.length < AllCount" @click="getList()">加载更多</div>
                </el-table>
            </el-row>
        </div>
        <el-dialog :title="detailDialog.isGetData ? (['个人运动员详情','队伍详情','组合详情'][detailDialog.info.TeamType - 1]) : ''" :visible.sync="detailDialog.show" :close-on-click-modal="false" class="detail_dialog">
            <template v-if="detailDialog.isGetData">
                <div class="content">
                    
                     <el-descriptions title="报名信息" :column="detailDialog.info.DeputationName ? 5 : 4" size="small" border direction="vertical" :labelStyle="labelStyle">
                        <!-- <template slot="extra">
                            <el-button type="primary" size="small">操作</el-button>
                        </template> -->
                        <el-descriptions-item label="比赛项目">{{detailDialog.info.EventsName}}</el-descriptions-item>
                        <el-descriptions-item label="组别">{{detailDialog.info.LeagueGroupName}}</el-descriptions-item>
                        <el-descriptions-item label="代表队" v-if="detailDialog.info.DeputationName">{{detailDialog.info.DeputationName}}</el-descriptions-item>
                        <el-descriptions-item label="代表机构">{{detailDialog.info.CompanyName || '无'}}</el-descriptions-item>
                        <el-descriptions-item label="报名时间">{{dateFormat(detailDialog.info.EnrollTime, 'yyyy-MM-dd HH:mm')}}</el-descriptions-item>
                    </el-descriptions>
                    <el-alert type="info" show-icon :closable="false" v-if="detailDialog.info.DeputationName && [1,3].includes(detailDialog.info.TeamType)">
                        <template #title>代表队在该组别报名了 {{detailDialog.ThisGroupDeputationEnrollNum}} {{['名运动员','','对组合'][detailDialog.info.TeamType - 1]}}</template>
                    </el-alert>
                    <el-descriptions title="报名操作员" :column="3" size="small" border direction="vertical" :labelStyle="labelStyle" v-if="detailDialog.operateInfo">
                        <el-descriptions-item label="姓名">{{detailDialog.operateInfo.Name}}</el-descriptions-item>
                        <el-descriptions-item label="手机号">{{detailDialog.operateInfo.Phone}}</el-descriptions-item>
                        <el-descriptions-item label="肖像照片" >
                            <span class="lookImg" @click="lookImg(detailDialog.operateInfo.PortraitPhoto)" v-if="detailDialog.operateInfo.PortraitPhoto">查看肖像照片</span>
                            <span v-else>无</span>
                        </el-descriptions-item>
                    </el-descriptions>
                    <template v-if="detailDialog.info.DeputationName">
                        <template v-if="detailDialog.info.TeamType == 2 && detailDialog.contactInfo">
                            <el-descriptions title="代表队联系人" :column="3" size="small" border direction="vertical" :labelStyle="labelStyle" v-if="detailDialog.info.TeamType==2">
                                <el-descriptions-item label="姓名">{{detailDialog.contactInfo.Name}}</el-descriptions-item>
                                <el-descriptions-item label="手机号">{{detailDialog.contactInfo.Phone}}</el-descriptions-item>
                                <el-descriptions-item label="肖像照片">
                                    <span class="lookImg" @click="lookImg(detailDialog.contactInfo.PortraitPhoto)" v-if="detailDialog.operateInfo.PortraitPhoto">查看肖像照片</span>
                                    <span v-else>无</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </template>
                        <template v-if="[1,3].includes(detailDialog.info.TeamType) && detailDialog.DeputationOtherUsers && detailDialog.DeputationOtherUsers.length > 0">
                            <el-descriptions title="代表队其他人员"></el-descriptions>
                            <el-table 
                                :data="detailDialog.DeputationOtherUsers"
                                border
                                size="mini"
                                style="width: 100%"
                            >
                                <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                                <el-table-column label="身份" min-width="100">
                                    <template slot-scope="scope">
                                        <template v-for="(item,index) in scope.row.Roles">
                                            <span :key="index">{{item.RoleName}}</span>
                                            <template v-if="index < scope.row.Roles.length - 1">，</template>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column label="姓名" min-width="80">
                                    <template slot-scope="scope">
                                        <span class="lookImg" @click="lookImg(scope.row.PortraitPhoto)">{{scope.row.Name}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="性别" width="50" align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.Gender == 1 ? '男' : '女'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="手机号" min-width="100" prop="Phone"></el-table-column>
                                <el-table-column label="身份证件" min-width="180">
                                    <template slot-scope="scope">
                                        <span class="lookImg" @click="lookImg(scope.row.IdentityPhoto1, scope.row.IdentityPhoto2)">{{scope.row.IdentityNo}}{{[1,3].includes(scope.row.IdentityType) ? '' : '（' + cards[scope.row.IdentityType] + '）'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="年龄" prop="Age" width="50" align="center"></el-table-column>
                            </el-table>
                        </template>
                    </template>
                    <template v-if="detailDialog.info.TeamType == 2">
                        <el-descriptions :title="detailDialog.info.DeputationName + '成员'"></el-descriptions>
                        <el-table
                            :data="detailDialog.members"
                            border
                            size="mini"
                            style="width: 100%"
                            :expand-row-keys="detailDialog.expandIds"
                            :row-key="getRowKey"
                            class="team_table"
                        >
                            <el-table-column label="序号" width="50" type="index" align="center" fixed></el-table-column>
                            <el-table-column label="身份" min-width="100" fixed>
                                <template slot-scope="scope">
                                    <template v-for="(item,index) in scope.row.Roles">
                                        <span :key="index">{{item.RoleName}}</span>
                                        <template v-if="index < scope.row.Roles.length - 1">，</template>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column label="姓名" min-width="80" fixed>
                                <template slot-scope="scope">
                                    <span class="lookImg" @click="lookImg(scope.row.PortraitPhoto)">{{scope.row.Name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="性别" width="50" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.Gender == 1 ? '男' : '女'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="手机号" prop="Phone" min-width="100"></el-table-column>
                            <el-table-column label="身份证件" min-width="180">
                                <template slot-scope="scope">
                                    <span class="lookImg" @click="lookImg(scope.row.IdentityPhoto1, scope.row.IdentityPhoto2)">{{scope.row.IdentityNo}}{{[1,3].includes(scope.row.IdentityType) ? '' : '（' + cards[scope.row.IdentityType] + '）'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="年龄" prop="Age" width="50" align="center"></el-table-column>
                            <el-table-column label="球衣号码" prop="JerseyNumber" width="50" align="center" v-if="field.jerseyNumber != 0"></el-table-column>
                            
                            <template v-if="ExtraDataDefines.length > 0">
                                <el-table-column v-for="extraItem in ExtraDataDefines" :label="extraItem.Name" :min-width="ExtraDataColumn[extraItem.DataType] || 80" :key="extraItem.Field">
                                    <template slot-scope="scope">
                                        <template v-if="extraItem.DataType == 'Text'">{{scope.row.Extras ? (scope.row.Extras[extraItem.Field] || '') : ''}}</template>
                                        <template v-else-if="extraItem.DataType == 'Enum'">{{scope.row.Extras ? (scope.row.Extras[extraItem.Field] || '') : ''}}</template>
                                        <template v-else-if="['Date', 'DateTime'].includes(extraItem.DataType)">{{dateFormat(scope.row.Extras ? scope.row.Extras[extraItem.Field] : '', extraItem.DataType == 'Date' ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm")}}</template>
                                    </template>
                                </el-table-column>
                            </template>
                            <el-table-column label="操作" v-if="type != 1" width="160">
                                <template slot-scope="scope" v-if="scope.row.IsAthlete">
                                    <template v-if="scope.row.ImpeachTime">
                                        <el-button type="info" size="mini" @click="report(2, scope.row.UserId, scope.row.ImpeachReason, scope.row.ImpeachTime)">修改</el-button>
                                        <el-button type="danger" size="mini" @click="cancelReport(scope.row.UserId)">撤销</el-button>
                                    </template>
                                    <template v-else>
                                        <el-button type="danger" size="mini" @click="report(1, scope.row.UserId)">举报</el-button>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column type="expand" width="1">
                                <template slot-scope="scope">
                                    <el-alert type="error" show-icon :closable="false"  v-if="scope.row.ImpeachTime && scope.row.IsAthlete">
                                        <template #title>该运动员于{{dateFormat(scope.row.ImpeachTime, "yyyy-MM-dd")}}被举报：{{scope.row.ImpeachReason}}</template>
                                    </el-alert>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                    <template v-else-if="[1, 3].includes(detailDialog.info.TeamType)">
                        <template v-for="(item, index) in detailDialog.members">
                            <el-descriptions :title="detailDialog.info.TeamType == 1 ? '运动员信息' :`运动员${index + 1} 信息`" :column="4" size="small" border direction="vertical" :labelStyle="labelStyle" :key="index">
                                <template slot="extra">
                                    <el-button 
                                        type="danger" 
                                        size="mini"
                                        @click="report(1, item.UserId)"
                                        v-if="type != 1 && !item.ImpeachTime && item.IsAthlete"
                                    >举报运动员</el-button>
                                    <template v-else>
                                        <el-button type="info" size="mini" @click="report(2, item.UserId, item.ImpeachReason, item.ImpeachTime)">修改</el-button>
                                        <el-button type="danger" size="mini" @click="cancelReport(item.UserId)">撤销</el-button>
                                    </template>
                                </template>
                                <el-descriptions-item label="姓名">{{item.Name}}</el-descriptions-item>
                                <el-descriptions-item label="性别">{{item.Gender == 1 ? '男' : '女'}}</el-descriptions-item>
                                <el-descriptions-item label="身份">
                                    <template v-for="(roleItem,roleIndex) in item.Roles">
                                        <span :key="roleIndex">{{roleItem.RoleName}}</span>
                                        <template v-if="roleIndex < item.Roles.length - 1">，</template>
                                    </template>
                                </el-descriptions-item>
                                <el-descriptions-item label="手机号码">{{item.Phone}}</el-descriptions-item>
                                <el-descriptions-item label="肖像照片">
                                    <span class="lookImg" @click="lookImg(item.PortraitPhoto)" v-if="item.PortraitPhoto">查看肖像照片</span>
                                    <span v-else>无</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="证件类型">{{cards[item.IdentityType]}}</el-descriptions-item>
                                <el-descriptions-item label="证件号码">
                                    <span class="lookImg" @click="lookImg(item.IdentityPhoto1, item.IdentityPhoto2)">{{item.IdentityNo}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="年龄">{{item.Age}}</el-descriptions-item>
                                <el-descriptions-item label="球衣号码" v-if="field.jerseyNumber != 0">{{item.JerseyNumber || '无'}}</el-descriptions-item>
                                <el-descriptions-item v-for="extraItem in ExtraDataDefines" :label="extraItem.Name" :key="extraItem.Field">
                                    <template v-if="extraItem.DataType == 'Text'">{{item.Extras ? (item.Extras[extraItem.Field] || '') : ''}}</template>
                                    <template v-else-if="extraItem.DataType == 'Enum'">{{item.Extras ? (item.Extras[extraItem.Field] || '') : ''}}</template>
                                    <template v-else-if="['Date', 'DateTime'].includes(extraItem.DataType)">{{dateFormat(item.Extras ? item.Extras[extraItem.Field] : '', extraItem.DataType == 'Date' ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm")}}</template>
                                </el-descriptions-item>
                                <el-descriptions-item v-for="i in getReplenishLength()" :key="i"></el-descriptions-item>
                            </el-descriptions>
                            <el-alert type="error" show-icon :closable="false"  v-if="item.ImpeachTime && item.IsAthlete">
                                <template #title>该运动员于{{dateFormat(item.ImpeachTime, "yyyy-MM-dd")}}被举报：{{item.ImpeachReason}}</template>
                            </el-alert>
                        </template>
                    </template>
                </div>
            </template>
            <template #footer>
                <el-descriptions :column="3" :labelStyle="{...labelStyle, width: '120px'}">
                    <el-descriptions-item label="审核状态">
                        <span :style="['','','color:#67C23A','color:#F56C6C'][detailDialog.info.AuditStatus]">{{['未提交','待审核','审核通过','审核不通过'][detailDialog.info.AuditStatus]}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="审核时间">{{dateFormat(detailDialog.info.AuditTime, "yyyy-MM-dd HH:mm")}}</el-descriptions-item>
                    <el-descriptions-item label="提交审核时间">{{dateFormat(detailDialog.info.SubmitTime, "yyyy-MM-dd HH:mm")}}</el-descriptions-item>
                    <el-descriptions-item label="审核不通过原因" v-if="detailDialog.info.AuditRejectReason">
                        <el-input type="textarea" resize="none" :rows="3" :value="detailDialog.info.AuditRejectReason" style="width: 100%;max-width:400px;"></el-input>
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="3" :labelStyle="{...labelStyle, width: '120px'}" v-if="detailDialog.info.LastAuditStatus && !([2,3].includes(detailDialog.info.AuditStatus))">
                    <el-descriptions-item label="上次审核状态">
                        <span :style="['','','color:#67C23A','color:#F56C6C'][detailDialog.info.LastAuditStatus]">{{['','','审核通过','审核不通过'][detailDialog.info.LastAuditStatus]}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="上次审核时间" :span="2">{{dateFormat(detailDialog.info.AuditTime, "yyyy-MM-dd HH:mm")}}</el-descriptions-item>
                    <el-descriptions-item label="审核不通过原因" v-if="detailDialog.info.AuditRejectReason">
                        <el-input type="textarea" resize="none" :rows="3" :value="detailDialog.info.AuditRejectReason" style="width: 100%;max-width:400px;"></el-input>
                    </el-descriptions-item>
                </el-descriptions>
                <div class="dialog-footer">
                    <template v-if="type != 1">
                        <div v-if="[1].includes(detailDialog.info.AuditStatus)">
                            <el-button type="success" @click="audit()">审核通过</el-button>
                            <el-button type="danger" @click="noAudit()">审核不通过</el-button>
                        </div>
                        <div v-else-if="[3].includes(detailDialog.info.AuditStatus)">
                            <el-button type="success" @click="audit()">改为审核通过</el-button>
                        </div>
                        <div v-else-if="[2].includes(detailDialog.info.AuditStatus)">
                            <el-button type="danger" @click="noAudit()">改为审核不通过</el-button>
                        </div>
                        <div v-else></div>
                    </template>
                    <template v-else>
                        <div></div>
                    </template>
                    <div>
                        <el-button @click="detailDialog.show = false">关&ensp;闭</el-button>
                    </div>
                </div>
            </template>
        </el-dialog>        
        <el-image ref="imagePreview" class="image_preview"
            src="~@/assets/images/logo.png" 
            :preview-src-list="imagePreviews">
        </el-image>
    </div>
</template>
<script>
import {
    GetLeagueEventsGroup, //获取分组
    GetLeagueEnrollDataAuditListList, //获取赛事报名数据
    GetLeagueEnrollTeamDetail, //获取队伍/个人报名详情
    ApproveLeagueEnrollTeam, //报名参赛审核通过
    RejectLeagueEnrollTeam, //报名参赛审核不通过
    GetOneLeagueEnrollDataAuditInfo,//获取单条记录数据
    ImpeachEnrollTeamMember,//举报参赛运动员
    CancelImpeachEnrollTeamMember,//撤销举报参赛运动员
} from '@/api'
import {
    GetLeagueEnrollFieldsOptions
} from '@/api/enroll';
import {dateFormat} from '@/assets/js';
export default {
    data(){
        var subLeagueId = parseInt(this.$route.params.subLeagueId),
            type = parseInt(this.$route.params.type);
        return {
            subLeagueId: isNaN(subLeagueId) ? 0 : subLeagueId,
            type: isNaN(type) ? 0 : type,//0统计审核 1查看不可修改
            labelStyle: {'width':'80px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'},
            statistics:{
                loading:false,
                list:[{GroupName:"1111111"},{GroupName:"1111111"},{GroupName:"1111111"},{GroupName:"1111111"}]
            },
            form:{
                companyName: "",
                teamName: "",
                memberName: "",
                groupId: 0,
                auditStatus: 4,
                prevAuditStatus: 0,
                isInform: 0,
                sort: 1
            },
            groups:[],
            auditStatusList:[
                {text: "不限", id: null},
                {text: "已提交", id: 4},
                {text: "未提交", id: 0},
                {text: "待审核", id: 1},
                {text: "审核通过", id: 2},
                {text: "审核不通过", id: 3}
            ],
            prevAuditStatusList:[
                {text: "不限", id: 0},
                {text: "通过", id: 2},
                {text: "不通过", id: 3},
                {text: "无", id: 4},
                {text: "通过或无", id: 5}
            ],
            informs:[
                {text: "不限", id: 0},
                {text: "有/是", id: 1},
                {text: "无/否", id: 2},
                {text: "全部/是", id: 3},
                {text: "可参赛", id: 4},
            ],
            sorts:[
                {text: "按报名时间，从早到晚", id: 1},
                {text: "按报名时间，从晚到早", id: 2},
                {text: "按提交时间，从早到晚", id: 3},
                {text: "按提交时间，从晚到早", id: 4},
                {text: "按审核时间，从早到晚", id: 5},
                {text: "按审核时间，从晚到早", id: 6},
            ],
            cards:{
                1:'身份证（港澳台居住证）',
                2:'护照',
                4:'台湾居民来往大陆通行证',
                5:'港澳居民来往内地通行证'
            },
            loading:false,
            isGet:false,
            lastId:0,
            list:[],
            AllCount:0,
            detailDialog:{
                show: false,
                teamId: 0,
                index: 0,
                isGetData: false,
                info:{},
                operateInfo:{},
                contactInfo:{},
                members:[],
                ThisGroupDeputationEnrollNum: 0,
                DeputationOtherUsers: []
            },
            field:{
                jerseyNumber: 0,
                portraitImg: 0
            },
            imagePreviews:[],
            ExtraDataDefines: [],
            ExtraDataColumn: {
                'DateTime': 140
            }
        }
    },
    created(){
        GetLeagueEventsGroup({
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                this.groups = data.GroupList;
            }
        })
        GetLeagueEnrollFieldsOptions({
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                let {
                    JerseyNumberOption = 0,
                    PortraitPhotoOption = 0
                } = data;
                this.field = {
                    jerseyNumber: JerseyNumberOption,
                    portraitImg: PortraitPhotoOption
                }
                this.ExtraDataDefines = data.ExtraDataDefines || [];
            }
        })
        this.getList();
    },
    methods:{
        dateFormat(value, format){
            if(!value) return '';
            return dateFormat(value, format) || '';
        },
        query(info){
            if(info){
                let form = this.form;
            }
            this.lastId = 0;
            this.getList();
        },
        getList(){
            var form = this.form;
            this.loading = true;
            var lastId = this.lastId;
            if(lastId == 0){
                this.list = [];
            }
            GetLeagueEnrollDataAuditListList({
                SubLeagueId: this.subLeagueId,
                CompanyName: form.companyName,
                deputatioanName: form.teamName,
                AthleteName: form.memberName,
                LeagueGroupId: form.groupId,
                AuditStatus: form.auditStatus,
                LastAuditStatus: form.prevAuditStatus,
                ImpeachState: form.isInform,
                TimeOrderType: (form.sort % 2 == 1 ? (form.sort + 1) : form.sort) / 2,
                TimeOrderIsAsc: form.sort % 2 == 1,
                continuation: {
                    LastId: lastId,
                    MaxCount: 20
                }
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    if(!this.isGet){
                        this.isGet = true;
                    }
                    this.AllCount = data.AllCount;
                    var ContinueList = data.ContinueList || [];
                    if(ContinueList.length == 0){
                    }else{
                        this.lastId = ContinueList[ContinueList.length - 1].TeamId;
                        this.$set(this,'list',[...this.list,...ContinueList]);
                    }
                }
            }).catch(()=>{
                this.loading = false;
            })
        },
        getSummaries(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
            });
            return sums;
        },
        //
        getRowKey(row){
            return row.IsAthlete ? `Athlete-${row.UserId}` : row.UserId;
        },
        openDetail(index, teamId, teamType){
            var detail = this.detailDialog;
            detail.show = true;
            detail.index = index;
            detail.isGetData = false;
            detail.teamId = teamId;
            const loading = this.$loading({
                lock: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            GetLeagueEnrollTeamDetail({
                SubLeagueId: this.subLeagueId,
                TeamId: teamId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    detail.info = data.BaseInfo || {};
                    detail.operateInfo = data.DeputationOperateUser;
                    detail.contactInfo = data.DeputationLinkUser;
                    detail.DeputationOtherUsers = data.DeputationOtherUsers || [];
                    detail.ThisGroupDeputationEnrollNum = data.ThisGroupDeputationEnrollNum || 0;
                    let members = data.Members || [];
                    detail.members = members;
                    detail.isGetData = true;
                    if(data.BaseInfo.TeamType == 2){
                        detail.expandIds = members.filter(item=>item.ImpeachTime && item.IsAthlete).map(item=>`Athlete-${item.UserId}`);
                    }
                    this.$set(this, 'detailDialog', detail);
                }else if(data.Code == 3893){
                    let list = this.list;
                    list.splice(index, 1);
                    this.$set(this,'list',list);
                    detail.show = false;
                    this.$message.error(`该${['个人运动员','队伍','组合'][teamType - 1]}记录已被删除！`);
                }else if(data.Code == 3894){
                    this.openDetail(index, teamId);
                    this.queryOneData(teamId);
                    this.$message.error(`该${['个人运动员','队伍','组合'][teamType - 1]}记录已被修改！`);
                }
            }).catch(()=>{
                loading.close();
            })
        },
        lookImg(){
            var imgs = [...arguments];
            if(imgs.length == 0){
                this.$message.warning('此队员未上传照片');
            }else{
                let imgs2 = [];
                for(var i=0;i<imgs.length;i++){
                    if(imgs[i]){
                        imgs2.push(imgs[i]);
                    }
                }
                if(imgs2.length == 0){
                    this.$message.warning('此队员未上传照片');
                }else{
                    this.imagePreviews = imgs2;
                    this.$refs['imagePreview'].showViewer = true;
                }
            }
        },
        report(type, userId, reason, time){
            var detail = this.detailDialog;
            this.$prompt(type == 2 ? `<div style="display:flex;justify-content: space-between;">
                    <span>举报原因：</span>
                    <span>举报时间：${dateFormat(time, "yyyy-MM-dd HH:mm")}</span>
                </div>` : '举报原因：', type == 2 ? '修改举报原因' : '举报运动员', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: type == 2 ? '保存修改' : '举报',
                cancelButtonText: '取消',
                inputType:'textarea',
                inputValue: reason,
                inputValidator:(value)=>{
                    if(!value){
                        return '请输入举报原因'
                    }
                }
            }).then(({ value }) => {
                ImpeachEnrollTeamMember({
                    "TeamId": detail.info.TeamId,
                    "AthlelteUserId": userId,
                    "ImpeachReason": value
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('操作成功');
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                    }else if(data.Code == 3893){
                        let list = this.list;
                        list.splice(detail.index, 1);
                        this.$set(this,'list',list);
                        detail.show = false;
                        this.$message.error(`该运动员记录已被删除！`);
                    }else if(data.Code == 3894){
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                        this.$message.error(`该运动员记录已被修改！`);
                    }
                })
            }).catch(() => {      
            });
        },
        cancelReport(userId){
            var detail = this.detailDialog;
            CancelImpeachEnrollTeamMember({
                "TeamId": detail.info.TeamId,
                "AthlelteUserId": userId
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success('撤销成功');
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                }else if(data.Code == 3893){
                    let list = this.list;
                    list.splice(detail.index, 1);
                    this.$set(this,'list',list);
                    detail.show = false;
                    this.$message.error(`该运动员记录已被删除！`);
                }else if(data.Code == 3894){
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                    this.$message.error(`该运动员记录已被修改！`);
                }
            })
        },
        audit(){
            var detail = this.detailDialog;
            ApproveLeagueEnrollTeam({
                "SubLeagueId": this.subLeagueId,
                "TeamId": detail.info.TeamId,
                "SubmitTime": detail.info.SubmitTime
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success('操作成功');
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                }else if(data.Code == 3893){
                    let list = this.list;
                    list.splice(detail.index, 1);
                    this.$set(this,'list',list);
                    detail.show = false;
                    this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被删除！`);
                }else if(data.Code == 3894){
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                    this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被修改！`);
                }
            })
        },
        noAudit(){
            var detail = this.detailDialog;
            this.$prompt('审核不通过的原因：', '审核不通过', {
                confirmButtonText: '审核不通过',
                cancelButtonText: '取消',
                inputType:'textarea',
                inputValidator:(value)=>{
                    if(!value){
                        return '请输入审核不通过的原因'
                    }
                }
            }).then(({ value }) => {
                RejectLeagueEnrollTeam({
                    "SubLeagueId": this.subLeagueId,
                    "TeamId": detail.info.TeamId,
                    "SubmitTime": detail.info.SubmitTime,
                    "RejectReason": value
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('操作成功');
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                    }else if(data.Code == 3893){
                        let list = this.list;
                        list.splice(detail.index, 1);
                        this.$set(this,'list',list);
                        detail.show = false;
                        this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被删除！`);
                    }else if(data.Code == 3894){
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                        this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被修改！`);
                    }
                })
            }).catch(() => {      
            });
        },
        queryOneData(TeamId){
            GetOneLeagueEnrollDataAuditInfo({
                "TeamId": TeamId
            }).then(data=>{
                if(data.Code == 0){
                    this.$set(this.list,this.detailDialog.index,data.AuditInfo);
                }
            })
        },
        getReplenishLength(){
            let len = (this.ExtraDataDefines.length + (this.field.jerseyNumber != 0 ? 1 : 0)) % 4;         
            if(len == 0) return 0;
            return 4 - len;
        }
    }
}
</script>
<style scoped>
.EnrollAuditDetail_content{
    padding-right: 20px;
    padding-bottom: 40px;
    box-sizing: border-box;
}
    .cell .el-button--text{
        padding: 0;
    }
    .form .el-row{
        margin-bottom: 0 !important;
    }
    .load_more{
        font-size: 16px;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
    }
    .load_more:hover{
        color: #409EFF;
    }
    .el-descriptions{
        margin-top: 20px;
    }
    .el-descriptions:first-of-type{
        margin-top: 0;
    }
    .el-descriptions::v-deep .el-descriptions__header{
        margin-bottom: 10px;
    }
    .lookImg{
        color: #409EFF;
        cursor: pointer;
    }
    .status > div{
        display: flex;
        text-align: left;
        padding: 5px 0;
        color: #606266;
    }
    .status > div > div{
        width: 33%;
        max-width: 300px;
        display: flex;
    }
    .status > div > div .label{
        width: 120px;
        text-align: right;
        display: inline-block;
        margin-right: 10px;
    }
    .status > div > div:first-of-type{
        max-width: 240px;
    }
    .status > div > div:first-of-type .label{
        width: auto;
        text-align: left;
    }
    .dialog-footer{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .image_preview{
        display: none;
    }
    .el-alert{
        border-radius: 0;
    }
    .team_table::v-deep .el-table__expanded-cell{
        padding: 0;
    }
    .team_table::v-deep .el-table__expand-column{
        pointer-events: none;
    }
    .team_table::v-deep .el-table__expand-column .cell{
        display: none;
    }
</style>
<style>
    .detail_dialog{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .detail_dialog .el-dialog{
        width: 80%;
        max-width: 1000px;
        margin: 0 !important;
    }
    .detail_dialog .el-dialog .el-dialog__body{
        max-height: calc(80vh - 54px - 126px);
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>